@tailwind base;
@tailwind components;
@tailwind utilities;

::-webkit-scrollbar {
    @apply w-6 h-4;
}

::-webkit-scrollbar-track {
    @apply bg-transparent;
}

::-webkit-scrollbar-thumb {
    @apply bg-neutral-200 dark:bg-neutral-800 bg-clip-content 
    rounded-full border-8 border-solid border-transparent
    hover:bg-neutral-300 dark:hover:bg-neutral-700;
}

* {
    @apply box-border outline-none;
}

@media (pointer: coarse) {
    *::-webkit-scrollbar {
        @apply hidden w-0 h-0;
        scrollbar-width: none;
    }
}